<template>
  <div>
    <div class="row">
      <div class="col-xl-9 col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-2">
              <a
                href="javascript: void(0);"
                class="text-dark font-size-24 font-weight-bold"
              >[Feature Request] How to enable custom font that comes from svg #2460</a>
            </div>
            <div class="mb-3">
              <a class="font-weight-bold" href="javascript: void(0);">zxs2162</a> wrote this post 12
              days ago · 0 comments
            </div>
            <div class="mb-4">
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >Umi</a>
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >React-framework</a>
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >Umijs</a>
            </div>
            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur!
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur!
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mb-2">
              <a
                href="javascript: void(0);"
                class="text-dark font-size-24 font-weight-bold"
              >London's mayor compared President Trump to an 11-year-old child</a>
            </div>
            <div class="mb-3">
              <a class="font-weight-bold" href="javascript: void(0);">hepu</a> wrote this post 12 days
              ago · 0 comments
            </div>
            <div class="mb-4">
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >Umi</a>
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >React-framework</a>
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >Umijs</a>
            </div>
            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur!
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur!
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="mb-2">
              <a
                href="javascript: void(0);"
                class="text-dark font-size-24 font-weight-bold"
              >What D-Day taught my grandpa</a>
            </div>
            <div class="mb-3">
              <a class="font-weight-bold" href="javascript: void(0);">Dankin</a> wrote this post 12 days
              ago · 0 comments
            </div>
            <div class="mb-4">
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >Umi</a>
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >React-framework</a>
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
              >Umijs</a>
            </div>
            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur!
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur! Lorem
                ipsum dolor sit amet, consectetur adipisicing elit. Nihil laborum est perferendis
                consectetur corporis esse labore minima molestias, exercitationem consequuntur!
              </p>
            </div>
          </div>
        </div>
        <a-pagination :total="50" />
      </div>
      <div class="col-xl-3 col-lg-12">
        <div class="pb-4 mb-3 border-bottom">
          <label class="font-weight-bold d-block" for="search-input">
            <span class="mb-2 d-inline-block">Search Post</span>
            <input
              class="form-control width-100p"
              type="text"
              placeholder="Search post..."
              id="search-input"
            />
          </label>
        </div>
        <div class="pb-4 mb-3 border-bottom">
          <label class="font-weight-bold d-block" for="subscribe-input">
            <span class="mb-2 d-inline-block">Subscribe</span>
            <input
              class="form-control width-100p"
              type="text"
              id="subscribe-input"
              placeholder="Enter your email..."
            />
          </label>
        </div>
        <div class="pb-4 mb-3 border-bottom">
          <div class="font-weight-bold mb-2">Categories</div>
          <div>
            <a
              href="javascript: void(0);"
              class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
            >Umi</a>
            <a
              href="javascript: void(0);"
              class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
            >React-framework</a>
            <a
              href="javascript: void(0);"
              class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
            >Umijs</a>
          </div>
        </div>
        <div class="pb-4 mb-3 border-bottom">
          <div class="font-weight-bold mb-3">Latest Posts</div>
          <kit-list-15 />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KitList15 from '@/components/kit/widgets/Lists/15/index'

export default {
  components: {
    KitList15,
  },
}
</script>
